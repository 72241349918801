import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { sanityConfig } from "../utils/sanity"

import { GatsbyImage } from "gatsby-plugin-image"
import classNames from "classnames"

import Banner from "../components/banner"
import Layout from "../components/layout"
import PortableText from "../components/portable-text"
import Seo from "../components/seo"

export default function PageTemplate({ data, pageContext }) {
  const { slug } = pageContext
  const { page } = data
  const [arrowActive, setArrowActive] = useState(false)

  // We assign a `template` value via Sanity. If the result is contained-width then we need to
  // adjust the template to show the sidebar etc. If the result is full-width then we should return
  // the full width variation.

  // Get quicklinks
  function getQuickLinks() {
    const quickLinks = page._rawBody
      ? page._rawBody.filter((item) => item._type === "quickLink")
      : []
    return quickLinks
  }

  const featureImage = page.featureImage
    ? getGatsbyImageData(
        page.featureImage.asset.id,
        { maxWidth: 1024 },
        sanityConfig
      )
    : null

  return (
    <Layout>
      <Seo title={page.title} />
      <Banner label={page.preStrapline}>{page.strapline}</Banner>

      <main className="container mx-auto">
        <div className="px-3 py-3 lg:py-12 lg:flex items-start">
          <button
            id="subNav"
            onClick={() => setArrowActive(!arrowActive)}
            className="lg:hidden py-1 text-lg font-semibold text-indigo-800 hover:text-indigo-600 flex items-center"
          >
            <span className="pr-2">{page.title}</span>
            <svg
              id="arrowDown"
              className={classNames("w-4 mt-1 fill-current block", {
                hidden: arrowActive,
              })}
              version="1.1"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m2.5 31.301c0-2.6016 1-5.1016 2.8984-7.1016 3.8984-3.8984 10.301-3.8984 14.199 0l30.402 30.301 30.398-30.398c3.8984-3.8984 10.301-3.8984 14.199 0 3.8984 3.8984 3.8984 10.301 0 14.199l-37.496 37.5c-1.8984 1.8984-4.3984 2.8984-7.1016 2.8984-2.6992 0-5.1992-1.1016-7.1016-2.8984l-37.5-37.402c-1.8984-2-2.8984-4.5977-2.8984-7.0977z" />
            </svg>
            <svg
              id="arrowUp"
              className={classNames("w-4 mt-1 fill-current block", {
                hidden: !arrowActive,
              })}
              version="1.1"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m97.5 68.699c0 2.6016-1 5.1016-2.8984 7.1016-3.8984 3.8984-10.301 3.8984-14.199 0l-30.402-30.301-30.398 30.301c-3.8984 3.8984-10.301 3.8984-14.199 0-3.8984-3.8984-3.8984-10.301 0-14.199l37.5-37.5c1.8984-1.8984 4.3984-2.8984 7.1016-2.8984 2.6992 0 5.1992 1.1016 7.1016 2.8984l37.5 37.5c1.8945 2 2.8945 4.5977 2.8945 7.0977z" />
            </svg>
          </button>
          {arrowActive && (
            <ul className="leading-loose font-semibold md:hidden">
              {getQuickLinks().map(
                (link) =>
                  link.title && (
                    <li key={link._key}>
                      <Link
                        onClick={() => setArrowActive(false)}
                        className="block p-1 text-indigo-800 hover:text-black"
                        to={`/${slug}#${link.ref}`}
                      >
                        {link.title}
                      </Link>
                    </li>
                  )
              )}
            </ul>
          )}

          <article className="leading-loose w-full max-w-4xl xl:text-lg">
            {featureImage && (
              <GatsbyImage className="mb-6 rounded" image={featureImage} />
            )}
            <PortableText blocks={page._rawBody} />
          </article>

          <aside
            id="sectionMenu"
            className="hidden lg:block lg:sticky self-start top-0 ml-12"
            style={{ width: 300 }}
          >
            <div className="lg:my-3">
              <p className="hidden lg:block lg:text-xl font-semibold text-gray-800 border-b-4 border-gray-200 mb-1">
                {page.title}
              </p>
              <ul className="leading-loose font-semibold">
                {getQuickLinks().map(
                  (link) =>
                    link.title && (
                      <li key={link._key}>
                        <Link
                          className="block p-1 text-indigo-800 hover:text-black"
                          to={`/${slug}#${link.ref}`}
                        >
                          {link.title}
                        </Link>
                      </li>
                    )
                )}
              </ul>
            </div>
          </aside>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    page: sanityPage(id: { eq: $id }) {
      featureImage {
        asset {
          id
        }
      }
      title
      strapline
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`
